<template>
  <div
    :id="id"
    ref="container-element"
    role="menu"
    tabindex="0"
    class="modern-color-theme font-poppins max-w-lg p-1 bg-neutral-100 rounded-lg flex flex-col shadow-sm overflow-y-auto max-h-96 z-[1001] ring-1 ring-inset ring-neutral-250 outline-none"
    :class="computedLayout.class"
    :style="computedLayout.style"
    data-component-name="VContextMenu"
    @keydown.esc="emit('close')"
  >
    <ul>
      <li v-for="(item, index) of flatItems" :key="index">
        <template v-if="item.type === 'button'">
          <a
            v-if="'href' in item"
            class="min-h-9 h-9 text-sm flex items-center px-3 gap-3 w-full whitespace-nowrap"
            :disabled="item.disabled"
            :title="item.title ?? item.label"
            :class="{ 'cursor-not-allowed text-neutral-400': item.disabled, 'hover:bg-neutral-150 text-neutral-950': !item.disabled }"
            :href="item.href"
            v-on="getListeners(undefined)"
          >
            <VIcon v-if="item.icon" :name="item.icon" />
            <div class="overflow-ellipsis overflow-hidden">
              {{ item.label }}
            </div>
          </a>
          <button
            v-else
            type="button"
            class="min-h-9 h-9 text-sm flex items-center px-3 gap-3 w-full whitespace-nowrap"
            :disabled="item.disabled"
            :title="item.title ?? item.label"
            :class="{ 'cursor-not-allowed text-neutral-400': item.disabled, 'hover:bg-neutral-150 text-neutral-950': !item.disabled }"
            @click.prevent="actionAndClose(item)"
            v-on="getListeners(undefined)"
          >
            <VIcon v-if="item.icon" :name="item.icon" />
            <div class="overflow-ellipsis overflow-hidden">
              {{ item.label }}
            </div>
          </button>
        </template>
        <template v-else-if="item.type === 'label'">
          <div
            class="min-h-9 h-9 text-sm font-medium leading-6 text-neutral-950 rounded-md flex items-center pl-3 pr-1"
            :class="{ 'justify-center': item.align === 'center' }"
            :title="item.label"
            v-on="getListeners(undefined)"
          >
            {{ item.label }}
          </div>
        </template>
        <template v-else-if="item.type === 'separator'">
          <hr>
        </template>
        <template v-else-if="item.type === 'search'">
          <VInput v-model="item.value" :placeholder="item.placeholder" @input="recomputeFlatItems">
            <template #suffix>
              <VIcon color="neutral" :name="item.icon ?? 'Solid/search'" />
            </template>
          </VInput>
        </template>
        <template v-else-if="item.type === 'draggable'">
          <VueDraggable v-model="item.items" item-key="key">
            <template #item="{element: subItem}: {element: V.ContextMenu.Draggable['items'][number]}">
              <div :key="subItem.key">
                <button
                  type="button"
                  class="min-h-9 h-9 text-sm flex items-center px-3 gap-3 w-full whitespace-nowrap"
                  :title="subItem.label"
                  :disabled="subItem.disabled"
                  :class="{ 'cursor-not-allowed text-neutral-400': subItem.disabled, 'hover:bg-neutral-150 text-neutral-950': !subItem.disabled }"
                  @click.prevent="setItemValue(subItem, !getItemValue(subItem))"
                  v-on="getListeners(undefined)"
                >
                  <VIcon size="xs" name="Outline/drag-indicator-vertical" />
                  <div class="overflow-ellipsis overflow-hidden">
                    {{ subItem.label }}
                  </div>
                  <VCheckbox :model-value="getItemValue(subItem)" class="ml-auto pointer-events-none" :tabindex="-1" />
                </button>
              </div>
            </template>
          </VueDraggable>
        </template>
        <template v-else-if="item.type === 'menu'">
          <button
            ref="submenuButtons"
            type="button"
            :title="item.label"
            :data-index="index"
            :disabled="item.disabled"
            class="min-h-9 h-9 text-sm flex items-center gap-3 px-3 pr-2 w-full whitespace-nowrap"
            :class="{ 'cursor-not-allowed text-neutral-400': item.disabled, 'hover:bg-neutral-150 text-neutral-950': !item.disabled }"
            @click.prevent
            v-on="getListeners(index)"
          >
            <VIcon v-if="item.icon" :name="item.icon" />
            <div class="overflow-ellipsis overflow-hidden">
              {{ item.label }}
            </div>
            <VIcon class="ml-auto" name="Solid/chevron-right" />
            <VContextMenu v-if="!item.disabled && submenuIndex === index" :context="props.context" :position="getSubmenuPosition(index)" :controller="props.controller || containerElement" :width="props.width" :items="item.items" @close="emit('close')" />
          </button>
        </template>
        <template v-else-if="item.type === 'checkbox'">
          <button
            type="button"
            class="min-h-9 h-9 text-sm flex items-center px-3 gap-3 w-full whitespace-nowrap"
            :disabled="item.disabled"
            :title="item.label"
            :class="{ 'cursor-not-allowed text-neutral-400': item.disabled, 'hover:bg-neutral-150 text-neutral-950': !item.disabled }"
            @click.prevent="setItemValue(item, !getItemValue(item))"
            v-on="getListeners(undefined)"
          >
            <VIcon v-if="item.icon" :name="item.icon" />
            <div class="overflow-ellipsis overflow-hidden">
              {{ item.label }}
            </div>
            <VCheckbox :model-value="getItemValue(item)" class="ml-auto pointer-events-none" :tabindex="-1" />
          </button>
        </template>
      </li>
    </ul>
  </div>
</template>
<script setup generic="T" lang="ts">
import VIcon from '../labels/VIcon.vue';
import VContextMenu from './VContextMenu.vue';
import { computed, onMounted, ref, toRef, useTemplateRef } from 'vue';
import VCheckbox from '../inputs/VCheckbox.vue';
import VInput from '../inputs/VInput.vue';
import { onClickOutsideOf } from '@component-utils/focus';
import VueDraggable from '@libs/zhyswan-vuedraggable/vuedraggable'
import { getFilteredCollection } from '@component-utils/search';
import type { V } from '@component-utils/types';
import { useManualTracking } from '@component-utils/reactivity';
import { useElementId } from '@component-utils/utils';

defineOptions({
  name: 'VContextMenu'
})

const props = withDefaults(
  defineProps<{
    items: V.ContextMenu.Item<T>[]
    position: 'absolute' | ({ [K in 'right' | 'left' | 'top' | 'bottom' | 'centerHorizontal']?: number } & { container?: { width: number, height: number } })
    context?: T
    width?: number
    /**
     * FOR INTERNAL USE ONLY
     */
    controller?: HTMLElement
  }>(),
  {
    width: 250,
    context: undefined,
    controller: undefined
  }
)

const id = useElementId(undefined)

const getItemValue = (item: V.ContextMenu.Checkbox) => {
  if (typeof item.value === 'boolean') return item.value
  else return item.value.get()
}

const setItemValue = (item: V.ContextMenu.Checkbox, value: boolean) => {
  if (typeof item.value === 'boolean') item.value = value
  else item.value.set(value)
}

const getListeners = (value: number | undefined) => ({
  mouseenter: () => submenuIndex.value = value,
  mouseleave: () => {},
  focus: () => submenuIndex.value = value,
  blur: () => {}
})

const submenuIndex = ref<number>()
const submenuButtons = ref<HTMLElement[]>([])

const emit = defineEmits<{
  close: []
}>()

defineExpose({
  get element () { return containerElement }
})

const items = toRef(props.items)

const { computedRef: flatItems, recompute: recomputeFlatItems } = useManualTracking(() => {
  return items.value.flatMap<V.ContextMenu.Item<T>>((item) => {
    if (item.type === 'search') {
      return [
        item,
        ...getFilteredCollection(item.items, item.value, ['label'], { fuzzy: true })
      ]
    } else if (item.type === 'sortable') {
      return item.items.toSorted((item1, item2) => +getItemValue(item2) - +getItemValue(item1))
    } else {
      return item
    }
  })
})

onMounted(() => {
  // Clear searches on mount
  for (const item of flatItems.value) {
    if (item.type === 'search') item.value = ''
  }
})

const actionAndClose = (item: V.ContextMenu.Button<T>) => {
  item.action(props.context)

  emit('close')
}

const containerElement = useTemplateRef('container-element')

onClickOutsideOf(
  [
    containerElement
  ],
  () => {
    if (props.controller) {
      // Do not close it on focus loss as it is controlled by the controller element
    } else {
      emit('close')
    }
  },
  {
    esc: true
  }
)

const windowSize = computed(() => {
  return {
    left: 0,
    top: 0,
    right: window.innerWidth,
    bottom: window.innerHeight
  }
})

const computedLayout = computed(() => {
  const menuWidth = props.width

  if (props.position === 'absolute') {
    return {
      class: 'absolute',
      style: {
        minWidth: `${menuWidth}px`,
        width: `${menuWidth}px`
      }
    }
  } else {
    const menuHeight = Math.min(384, 36 * flatItems.value.length)

    const { right: windowWidth, bottom: windowHeight } = windowSize.value

    const position: { [K in 'right' | 'left' | 'top' | 'bottom']?: string } = {}

    const { height: containerHeight, width: containerWidth } = props.position.container ?? { width: 0, height: 0 }

    if (typeof props.position.centerHorizontal === 'number') {
      const left = props.position.centerHorizontal - menuWidth / 2
      const right = props.position.centerHorizontal + menuWidth / 2

      if (left + menuWidth > windowWidth) {
        if (right - menuWidth < 0) {
          position.left = `${right}px`
        } else {
          position.right = `${windowWidth - right}px`
        }
      } else {
        position.left = `${left}px`
      }
    } else if (typeof props.position.left === 'number') {
      const left = props.position.left

      if (left + menuWidth > windowWidth) {
        position.right = `${windowWidth - left}px`
      } else {
        position.left = `${left}px`
      }
    } else if (typeof props.position.right === 'number') {
      const right = props.position.right

      if (right - menuWidth < 0) {
        position.left = `${right}px`
      } else {
        position.right = `${windowWidth - right}px`
      }
    }

    if (typeof props.position.top === 'number' && typeof props.position.bottom === 'number') {
      const top = props.position.top
      const bottom = props.position.bottom

      if (top + menuHeight > windowHeight) {
        if (bottom - menuHeight < 0) {
          position.top = `${bottom}px`
        } else {
          position.bottom = `${windowHeight - bottom}px`
        }
      } else {
        position.top = `${top}px`
      }
    } else if (typeof props.position.top === 'number') {
      const top = props.position.top

      if (top + menuHeight > windowHeight) {
        position.bottom = `${windowHeight - top + containerHeight}px`
      } else {
        position.top = `${top}px`
      }
    } else if (typeof props.position.bottom === 'number') {
      const bottom = props.position.bottom

      if (bottom - menuHeight < 0) {
        position.top = `${bottom}px`
      } else {
        position.bottom = `${windowHeight - bottom}px`
      }
    }

    return {
      class: 'fixed',
      style: Object.assign(
        {
          minWidth: `${menuWidth}px`,
          width: `${menuWidth}px`
        },
        position
      )
    }
  }
})

const getSubmenuPosition = (index: number) => {
  const button = submenuButtons.value.find((button) => button.dataset.index === String(index))

  if (button) {
    const rect = button.getBoundingClientRect()

    const menuWidth = props.width

    const position: { [K in 'right' | 'left' | 'top' | 'bottom']?: number } = {
      top: rect.top - 4,
      bottom: rect.bottom + 4,
    }

    const { right: windowWidth } = windowSize.value

    if (rect.right + menuWidth > windowWidth) {
      position.right = rect.left
    } else {
      position.left = rect.right
    }

    return position
  } else {
    throw new Error('Submenu position not available')
  }
}
</script>
